import store from '@/store';
import { authHelper } from '@homeserve/vue-auth-plugin';
import Axios from 'axios';

const http = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

http.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${authHelper.getUserToken(store)}`;

  return request;
});

http.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (authHelper.isUserAuthenticated(store)) {
      await authHelper.refreshToken(store);
    }
    if (error.config && error.response && (error.response.status === 401 || error.response.status === 403)) {
      if (!authHelper.isAppAuthenticated(store) && !authHelper.isUserAuthenticated(store)) {
        await authHelper.authenticateApp(store, store.getters.partner.name);
      }

      const bearer = `Bearer ${authHelper.getUserToken(store)}`;
      http.defaults.headers.Authorization = bearer;
      error.config.headers.Authorization = bearer;
      return Axios.request(error.config);
    }

    return Promise.reject(error);
  },
);

export const checkAuth = async () => {
  if (!authHelper.isAppAuthenticated(store) && !authHelper.isUserAuthenticated(store)) {
    await authHelper.authenticateApp(store, store.getters.partner.name);
  }
};

export { http };
