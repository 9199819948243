import { IJob } from '@homeserve/od-typings/api';
import ct from 'countries-and-timezones';
import moment from 'moment';
import momentTz from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

export function updateLocale() {
  moment.updateLocale('fr', {
    longDateFormat: {
      LT: 'HH[h]mm',
      LTS: moment.localeData().longDateFormat('LTS'),
      L: moment.localeData().longDateFormat('L'),
      LL: moment.localeData().longDateFormat('LL'),
      LLL: moment.localeData().longDateFormat('LLL'),
      LLLL: moment.localeData().longDateFormat('LLLL'),
    },
  });
}

export function minutesToHumanDuration(minutes: number) {
  return minutes > 60
    ? minutes % 60 === 0
      ? Math.floor(minutes / 60) + 'h'
      : Math.floor(minutes / 60) + 'h' + (minutes % 60)
    : minutes + ' min';
}

export function getTimeZoneFromJob(job: IJob) {
  if (job.timeZoneId) {
    return job.timeZoneId;
  }

  const countryCode = job.country || 'FR';

  return ct.getTimezonesForCountry(countryCode) &&
    ct.getTimezonesForCountry(countryCode)[0] &&
    ct.getTimezonesForCountry(countryCode)[0].name
    ? ct.getTimezonesForCountry(countryCode)[0].name
    : 'Europe/Paris';
}

export function getTimeZonedDate(date: Date, timeZone: string) {
  return momentTz(date).tz(timeZone);
}

export function formatDate(value, format = 'DD/MM/YYYY HH:mm') {
  if (!value) {
    return '';
  }

  return moment(value).format(format);
}

export function formatHour(value, format = 'LT') {
  if (!value) {
    return '';
  }

  return moment(value, 'HH:mm').format(format);
}
