import Store from '@/store';
import { Vue } from 'vue-property-decorator';

const updateStickyHeight = (el, binding) => {
  if (binding.value) {
    el.style.position = 'fixed';
    el.style.bottom = '0';
    el.style.left = '0';
    el.style.right = '0';
    el.style.zIndex = '999';
  } else {
    el.style.position = 'initial';
    el.style.bottom = '';
    el.style.left = '';
    el.style.right = '';
    el.style.zIndex = '';
  }

  Store.dispatch('setStickyFooterHeight', binding.value ? el.getBoundingClientRect().height : 0);
};

Vue.directive('sticky-footer', {
  bind(el, binding) {
    updateStickyHeight(el, binding);
  },
  update(el, binding) {
    updateStickyHeight(el, binding);
  },
  unbind() {
    Store.dispatch('setStickyFooterHeight', 0);
  },
});
