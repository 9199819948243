import { getDefaultLanguage } from '@homeserve/vue-components-plugin/src/utils/languageHelper';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from './../store';
import { messages as en } from './en';
import { messages as fr } from './fr';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: getDefaultLanguage(store.getters.partner),
  messages: {
    fr,
    en,
  },
});

export default i18n;
