export const messages = {
  menu: {
    plumbing: 'plumbing',
    electricity: 'electricity',
    gas: 'gas',
    air_condition: 'air condition',
    heating: 'heating',
    follow_order: 'follow your order',
    who: 'who',
    help: 'help',
    pro_access: 'professional access',
    specify_your_problem_and_choose_the_intervention_slot: 'Specify your problem and choose the intervention slot',
    choose_a_file_or_drag_it_here: 'Choose a file or drag it here',
    please_select_an_intervention_slot: 'Please select an intervention slot',
    order_my_repair_service: 'Order my repair service',
    add_photos_diagrams_or_your_best_drawings_to_help_us_understand_the_problem:
      'Add photos, diagrams or your best drawings to help us understand the problem',
    your_preferences_will_be_communicated_to_the_convenience_store_who_will_call_you_back_to_schedule_the_appointment:
      'Your preferences will be communicated to the convenience store who will call you back to schedule the appointment',
    select_your_appointment_preferences: 'Select your appointment preferences',
    any_questions: 'Any questions?',
    consult_our_faq: 'constult our FAQ',
    or_contact_us_at: 'or contact us at',
    free_service_plus_cost_of_the_call: 'free service + cost of the call',
    or_by_email_at: 'or by email at',
  },
};
