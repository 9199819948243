import colors from 'vuetify/es5/util/colors';

export default {
  width: { lg: 1200, xl: 1200, md: 900 },
  'grid-padding': {
    xs: 2,
    sm: 4,
    md: 8,
    lg: 16,
    xl: 24,
  },
  primary: { base: '#4b2f91' },
  secondary: { base: '#e7342d' },
  tertiary: { base: '#2f5a91' },
  success: colors.green.lighten2,
  error: colors.red,
  warning: colors.amber,
  info: colors.lightBlue.lighten3,
  text: {
    base: '#333333',
    lighten1: '#999999',
    lighten4: '#E2E2E2',
    lighten5: '#fff',
  },
  border: {
    base: '#ddd',
  },
  background: {
    base: '#edeeef',
    lighten1: '#f2f2f2',
    lighten2: '#f4f5f6',
    lighten3: '#f8f9fa',
    lighten4: '#fcfcfc',
    lighten5: '#fff',
    darken3: '#343a40',
    darken5: '#000',
  },
  electricity: { base: '#fed403' },
  heatingSystem: { base: '#f07e23' },
  plumbing: { base: '#3bb9b2' },
  gas: { base: '#4c2484' },
  airConditioning: { base: '#e2e2e2' },
  overrideHomeDiagnosticBG: { base: '#878faa' }
};
