export const cleanOldLocalStorageVersions = (version: string) => {
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    if (localStorage.key(i) !== null) {
      const key = localStorage.key(i);
      if (
        key &&
        key.startsWith(process.env.VUE_APP_LOCALSTORAGE_KEY || 'ondemand') &&
        key !== `${process.env.VUE_APP_LOCALSTORAGE_KEY}_${version}`
      ) {
        localStorage.removeItem(key);
      }
    }
  }
};
